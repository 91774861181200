import React from 'react';
import logo from '../assets/img/MU LOGO.png'
import facebook from '../assets/img/Facebook.png'
import twitter from '../assets/img/Twitter.png'
import instagram from '../assets/img/Instagram.png'
import youtube from '../assets/img/Youtube.png'
import './layout.css'


function Footer(props) {
  return (


    <>
      <div
        className="container-fluid"
        style={{backgroundColor: '#222222', padding: '63px 87px'}}
      >


        <div className="row">

          <div className="col-12 d-lg-flex justify-content-lg-between">

            <div>

              <div className="w-100 mb-4 d-flex gap-2">

                <img
                  alt="School Logo"
                  src={logo}
                  style={{height: '50px', width: '50px', marginRight: '1rem'}}
                />

                <h3
                  className="mt-lg-3"
                  style={{fontWeight: '600', fontSize: '20px', lineHeight: '22.1px', color: '#fff'}}
                >
                  Maduka University
                </h3>


              </div>

              <div className="mb-4">

                <h3
                  style={{fontWeight: '500', fontSize: '16px', lineHeight: '20px', color: '#fff'}}
                >
                  Along Nsukka - Enugu New Road, Ekwegbe, Enugu State, Nigeria
                </h3>

              </div>

              <div className="mb-4">
                <h3
                  style={{fontWeight: '500', fontSize: '16px', lineHeight: '20px', color: '#fff'}}
                >
                  Official Email Address: <br/> admission@madukauniversity.edu.ng
                </h3>
              </div>

            </div>

            <div>

              <h3
                style={{color: '#0072e7', marginBottom: '2rem'}}
              >
                Helpful Links
              </h3>

              <div className="row row-cols-lg-3 justify-content-lg-between">

                <div className="col-12 col-lg d-flex flex-column gap-3 mb-3 mb-lg-0">

                  <a
                    className="helpful-links"

                  >
                    Contact Us
                  </a>

                  <a
                    className="helpful-links"

                  >
                    Private Policy
                  </a>

                  <a
                    className="helpful-links"

                  >
                    FAQ
                  </a>

                  <a
                    className="helpful-links"

                  >
                    Campus Tour
                  </a>

                  <a
                    className="helpful-links"

                  >
                    NUC
                  </a>

                  <a
                    className="helpful-links"

                  >
                    Webometrics
                  </a>

                </div>

                <div className="col-12 col-lg d-flex flex-column gap-3 mb-3 mb-lg-0">

                  <a
                    className="helpful-links"
                    href="https://portal.madukauniversity.edu.ng/home"
                  >
                    Portal
                  </a>

                  <a
                    className="helpful-links"

                  >
                    Gallery
                  </a>

                  <a
                    className="helpful-links"
                    href="https://admissions.madukauniversity.edu.ng"
                  >
                    Admission
                  </a>

                  <a
                    className="helpful-links"
                    href="http://elearn.madukauniversity.edu.ng"
                  >
                    E-Learning
                  </a>


                  <a
                    className="helpful-links"
                    href="https://oer.madukauniversity.edu.ng/home"
                  >
                    OER Resources
                  </a>

                  <a
                    className="helpful-links"

                  >
                    Ministry of Education
                  </a>


                </div>

                <div className="col-12 col-lg d-flex flex-column gap-3 mb-3 mb-lg-0">


                  <a
                    className="helpful-links"

                  >
                    Employment Opportunities
                  </a>

                  <a
                    className="helpful-links"

                  >
                    Faculty and Staff Profile
                  </a>

                  <a
                    className="helpful-links"

                  >
                    Administrative
                  </a>

                  <a
                    className="helpful-links"

                  >
                    Google Scholar
                  </a>

                  <div className="mt-5 mb-3 mb-lg-0">

                    <h6
                      style={{color: '#0072E7', fontSize: '16px', fontWeight: '500', lineHeight: '20px'}}
                    >
                      Follow us
                    </h6>

                    <div className="d-flex gap-3">

                      <a
                        className="helpful-links"
                        href="https://www.facebook.com/madukauniversity"
                      >
                        <img
                          src={facebook}
                          alt="Facebook"
                        />
                      </a>


                      <a
                        className="helpful-links"
                        href="https://www.instagram.com/madukauniversity/"
                      >
                        <img
                          src={instagram}
                          alt="Facebook"
                        />
                      </a>


                      <a
                        className="helpful-links"
                        href="https://twitter.com/madukauning"
                      >
                        <img
                          src={twitter}
                          alt="Facebook"
                        />
                      </a>


                      <a
                        className="helpful-links"
                      >
                        <img
                          src={youtube}
                          alt="Facebook"
                        />
                      </a>


                    </div>


                  </div>
                </div>

              </div>


            </div>

          </div>

        </div>

      </div>

      <div
        className="container-fluid"
        style={{padding: '20px 166px', backgroundColor: '#0b0b0b'}}
      >

        <div className="row py-2">

          <div className="col-12 d-flex justify-content-between align-items-center text-center">

            <h6
              style={{fontSize: '14px', fontWeight: '500', color: '#ffff', lineHeight: '15px'}}
            >
              &copy; 2023. Maduka University.
            </h6>

            <h6
              style={{fontSize: '14px', fontWeight: '500', color: '#ffff', lineHeight: '15px'}}
            >
              Powered by Raadaa partners International
            </h6>

          </div>

        </div>

      </div>
    </>
  );

}

export default Footer;
